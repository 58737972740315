<!--=========================================================================================
  File Name: BeadRoad.vue
  Description: Bead Road Result Component
==========================================================================================-->
<template>
<div class="fit">
    <!-- Banker -->
    <div v-if="shoe == 'a'" class="fit">
        <div class='a shoe-item shoe-text'>
            <span class='res-span'>B</span>
        </div>
    </div>
    <div v-else-if="shoe == 'b'" class="fit">
        <div class='b shoe-item shoe-text'>
            <span class='res-span'>B</span>
        </div>
    </div>
    <div v-else-if="shoe == 'c'" class="fit">
        <div class='c shoe-item shoe-text'>
            <span class='res-span'>B</span>
        </div>
    </div>
    <div v-else-if="shoe == 'd'" class="fit">
        <div class='d shoe-item shoe-text'>
            <span class='res-span'>B</span>
        </div>
    </div>
    <!-- Player -->
    <div v-else-if="shoe == 'e'" class="fit">
        <div class='e shoe-item shoe-text'>
            <span class='res-span'>P</span>
        </div>
    </div>
    <div v-else-if="shoe == 'f'" class="fit">
        <div class='f shoe-item shoe-text'>
            <span class='res-span'>P</span>
        </div>
    </div>
    <div v-else-if="shoe == 'g'" class="fit">
        <div class='g shoe-item shoe-text'>
            <span class='res-span'>P</span>
        </div>
    </div>
    <div v-else-if="shoe == 'h'" class="fit">
        <div class='h shoe-item shoe-text'>
            <span class='res-span'>P</span>
        </div>
    </div>

    <!-- Tie -->
    <div v-else-if="shoe == 'i'" class="fit">
        <div class='i shoe-item shoe-text'>
            <span class='res-span'>T</span>
        </div>
    </div>
    <div v-else-if="shoe == 'j'" class="fit">
        <div class='j shoe-item shoe-text'>
            <span class='res-span'>T</span>
        </div>
    </div>
    <div v-else-if="shoe == 'k'" class="fit">
        <div class='k shoe-item shoe-text'>
            <span class='res-span'>T</span>
        </div>
    </div>
    <div v-else-if="shoe == 'l'" class="fit">
        <div class='l shoe-item shoe-text'>
            <span class='res-span'>T</span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'a-big-road'">
        <div class='a-big-road big-road-shoe'>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'b-big-road'">
        <div class='b-big-road big-road-shoe'>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'c-big-road'">
        <div class='c-big-road big-road-shoe'>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'd-big-road'">
        <div class='d-big-road big-road-shoe'>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'e-big-road'">
        <div class='e-big-road big-road-shoe '>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'f-big-road'">
        <div class='f-big-road big-road-shoe '>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'g-big-road'">
        <div class='g-big-road big-road-shoe '>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'h-big-road'">
        <div class='h-big-road big-road-shoe '>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'i-big-road'">
        <div class='i-big-road big-road-shoe '>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'j-big-road'">
        <div class='j-big-road big-road-shoe '>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'k-big-road'">
        <div class='k-big-road big-road-shoe '>
            <span></span>
        </div>
    </div>
    <div class="big-road-div fit" v-else-if="shoe == 'l-big-road'">
        <div class='l-big-road big-road-shoe '>
            <span></span>
        </div>
    </div>
    <!-- Big Eyed Road -->
    <div class="big-eye-road-div" v-else-if="shoe == 'a-big-eye-road'">
        <div class='a-big-eye-road big-road-shoe'>
            <span></span>
        </div>
    </div>
    <div class="big-eye-road-div" v-else-if="shoe == 'b-big-eye-road'">
        <div class='b-big-eye-road big-road-shoe'>
            <span></span>
        </div>
    </div>
    <div class="big-eye-road-div" v-else-if="shoe == 'e-big-eye-road'">
        <div class='e-big-eye-road big-road-shoe'>
            <span></span>
        </div>
    </div>
    <!-- Small Road -->
    <div class="small-road-div" v-if="shoe == 'a-small-road'">
        <div class='a-small-road small-road-shoe'>
            <span></span>
        </div>
    </div>
    <div class="small-road-div" v-if="shoe == 'e-small-road'">
        <div class='e-small-road small-road-shoe'>
            <span></span>
        </div>
    </div>
    <!-- Cockroach Road -->
    <div class="cockroach-road-div" v-if="shoe == 'a-cockroach-road'">
        <div class='a-cockroach-road cockroach-road-shoe'>
            <span></span>
        </div>
    </div>
    <div class="cockroach-road-div" v-if="shoe == 'e-cockroach-road'">
        <div class='e-cockroach-road cockroach-road-shoe'>
            <span></span>
        </div>
    </div>
    <!-- Empty divs -->
    <div class="big-road-div" v-else-if="shoe == '-br'">
        <div class=''>
            <span></span>
        </div>
    </div>
    <div class="big-eye-road-div" v-else-if="shoe == '-rber' || shoe == '-sr' || shoe == '-cr' || shoe == ''">
        <div class=''>
            <span></span>
        </div>
    </div>
    <div v-else-if="shoe == ''" class="fit">
        <div class=''>
            <span></span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'BeadRoad',
    props: {
        shoe: {
            required: true,
            type: String
        },
        tieCount: {
            required: false,
            type: String
        }
    }
}
</script>

<style>
.lasttd {
    animation: blink 1s;
    /* background-color: blue; */
    animation-iteration-count: 4;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}
</style>
