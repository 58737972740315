<!--=========================================================================================
  File Name: Result CockroachRoad.vue
  Description: Result Cockroach Road Result Component
==========================================================================================-->
<template>
  <div class="content" style="padding: 0px">
    <div class="cockroach-road">
      <div class="rs-ctnr d-flex flex-row grid-y grid-y-repeat-big-pcg">
        <div class="flex-column" v-for="(shoeM, x) in shoeCockroach" :key="x">
          <div
            v-for="(shoe, i) in shoeM"
            :key="i"
            style="height:1vh;width: 1vh;"
          >
            <div
              class="big-road-shoe"
              style="height:0.9vh;width: 0.9vh;"
              v-bind:class="[
                {
                  animate_latest:
                    x == shoeCockroach.length - 1 &&
                    shoe.shoe != '' &&
                    i == $getLasttIndex(shoeCockroach, x) &&
                    !hidePredict && predict.length <= 0,
                },
                {
                  animate_predict:
                    x == shoeCockroach.length - 1 &&
                    shoe.shoe != '' &&
                    i == $getLasttIndex(shoeCockroach, x) &&
                    hidePredict,
                },
                shoe.shoe + '-cockroach-road',
              ]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ResultCockroachRoad",
  components: {},
  data() {
    return {};
  },
  props: {
    shoeResults: { required: true, type: String },
    predict: {
      required: false,
      type: String,
    },
    hidePredict: {
      required: false,
      type: Boolean,
    },
  },
  watch: {
    shoeResults: {
      handler() {
        this.$store.commit(
          "gameModule/SET_COCKROACHROAD_COUNT",
          this.shoeResults.length
        );
      },
    },
  },
  computed: {
    shoeMatrix() {
      let matrix = [],
        prevShoe = "",
        columnIdx = -1,
        rowIdx = 1,
        sameShoeCnt = 0,
        tempColumnIdx = -1,
        tmpInd = 5,
        tieCnt = 0,
        smallRoadArr = [],
        shoeR = "";
      const tieShoe = ["i", "j", "k", "l"];

      if (this.predict != undefined && this.hidePredict) {
        shoeR = this.shoeResults + this.predict;
      } else {
        shoeR = this.shoeResults;
      }
      shoeR.split("").forEach((shoe) => {
        if (!(tieShoe.indexOf(shoe) !== -1)) {
          // If shoe is not tie
          if (!(this.$prevShoeArr(prevShoe).indexOf(shoe) !== -1)) {
            // create new column
            if (matrix[columnIdx + 1]) {
              // check if there's an over shoe, overwrite 1st column
              matrix[columnIdx + 1][0] = {
                shoe,
                count: 0,
                isDragon: 0,
                isNextCol: 0,
              };
            } else {
              matrix.push([
                { shoe, count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
              ]);
            }
            rowIdx = 1; // reset row index to 1 (next vertical shoe)
            sameShoeCnt = 0; // reset same shoe count
            columnIdx++; // increment column index
            tempColumnIdx = columnIdx;
            tieCnt = 0;
            if (columnIdx >= 4) {
              smallRoadArr.push(this.$shoePredictThree(matrix, columnIdx, 0));
            }
          } else {
            // normal add
            if (sameShoeCnt > rowIdx) {
              matrix.push([
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
              ]);
              tempColumnIdx++;
              matrix[tempColumnIdx][rowIdx] = {
                shoe,
                count: 0,
                isDragon: 1,
                isNextCol: 1,
              };

              if (
                matrix[tempColumnIdx - 1][0].shoe != "" &&
                matrix[tempColumnIdx - 1][1].shoe != "" &&
                matrix[tempColumnIdx - 1][2].shoe != "" &&
                matrix[tempColumnIdx - 1][3].shoe != "" &&
                matrix[tempColumnIdx - 1][4].shoe != "" &&
                matrix[tempColumnIdx - 1][5].shoe != ""
              ) {
                matrix[tempColumnIdx - 1][0].isDragon = 1;
                matrix[tempColumnIdx - 1][1].isDragon = 1;
                matrix[tempColumnIdx - 1][2].isDragon = 1;
                matrix[tempColumnIdx - 1][3].isDragon = 1;
                matrix[tempColumnIdx - 1][4].isDragon = 1;
                matrix[tempColumnIdx - 1][5].isDragon = 1;
              }

              if (columnIdx >= 3) {
                smallRoadArr.push("a");
              }

              if (tmpInd < 5) {
                //
                if (matrix[tempColumnIdx][rowIdx + 1].shoe == "") {
                  tempColumnIdx--;
                  rowIdx++;
                  tmpInd = rowIdx;
                }
              }
            } else {
              if (
                matrix[tempColumnIdx] &&
                matrix[tempColumnIdx][rowIdx].shoe == ""
              ) {
                matrix[tempColumnIdx][rowIdx] = {
                  shoe,
                  count: 0,
                  isDragon: 0,
                  isNextCol: 0,
                };
                if (columnIdx >= 3) {
                  if (matrix[tempColumnIdx][rowIdx].shoe != "") {
                    smallRoadArr.push(
                      this.$shoePredictThree(matrix, tempColumnIdx, rowIdx)
                    );
                  }
                }
              } else {
                tmpInd = rowIdx - 1;
                // check if the matrix has enough length to handle dragon tail
                if (matrix.length < tempColumnIdx) {
                  matrix.push([
                    { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                    { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                    { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                    { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                    { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                    { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                  ]);
                }

                matrix.push([
                  { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                  { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                  { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                  { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                  { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                  { shoe: "", count: 0, isDragon: 0, isNextCol: 0 },
                ]);

                tempColumnIdx++;
                rowIdx--;
                matrix[tempColumnIdx][rowIdx] = {
                  shoe,
                  count: 0,
                  isDragon: 1,
                  isNextCol: 1,
                };
                if (columnIdx >= 3) {
                  smallRoadArr.push("a");
                }

                // Always check the bottom index
                if (tmpInd < 5) {
                  if (matrix[tempColumnIdx][rowIdx + 1].shoe == "") {
                    tempColumnIdx--;
                    rowIdx++;
                    tmpInd = rowIdx;
                  }
                }
              }
            }
            if (rowIdx < tmpInd) rowIdx++; // increment row index
          }
          prevShoe = shoe; // set current shoe as prev
          sameShoeCnt++; // same Shoe
          tieCnt = 0; // reset tie counting.
        } else {
          tieCnt++;
          if (rowIdx > -1 && tempColumnIdx > -1) {
            matrix[tempColumnIdx][rowIdx - 1] = {
              shoe: matrix[tempColumnIdx][rowIdx - 1].shoe,
              count: tieCnt,
              isDragon: matrix[tempColumnIdx][rowIdx - 1].isDragon,
              isNextCol: matrix[tempColumnIdx][rowIdx - 1].isNextCol,
            };
          }
        }
      });

      return smallRoadArr;
    },
    shoeCockroach() {
      let smallArr = this.shoeMatrix;
      let matrix = [],
        prevShoe = "",
        columnIdx = -1,
        rowIdx = 1,
        sameShoeCnt = 0,
        tempColumnIdx = -1,
        tmpInd = 5;
      smallArr.forEach((shoe) => {
        if (!(this.$prevShoeArr(prevShoe).indexOf(shoe) !== -1)) {
          // create new column
          if (matrix[columnIdx + 1]) {
            // check if there's an over shoe, overwrite 1st column
            matrix[columnIdx + 1][0] = { shoe, count: 0 };
          } else {
            matrix.push([
              { shoe, count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
            ]);
          }
          rowIdx = 1; // reset row index to 1 (next vertical shoe)
          sameShoeCnt = 0; // reset same shoe count
          columnIdx++; // increment column index
          tempColumnIdx = columnIdx;
        } else {
          // normal add
          if (sameShoeCnt > rowIdx) {
            matrix.push([
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
            ]);
            tempColumnIdx++;
            matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 };
            if (tmpInd < 5) {
              //
              if (matrix[tempColumnIdx][rowIdx + 1].shoe == "") {
                tempColumnIdx--;
                rowIdx++;
                tmpInd = rowIdx;
              }
            }
          } else {
            if (
              matrix[tempColumnIdx] &&
              matrix[tempColumnIdx][rowIdx].shoe == ""
            ) {
              matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 };
            } else {
              tmpInd = rowIdx - 1;
              // check if the matrix has enough length to handle dragon tail
              if (matrix.length < tempColumnIdx) {
                matrix.push([
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                ]);
              }

              matrix.push([
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
              ]);
              tempColumnIdx++;
              rowIdx--;
              matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 };

              // Always check the bottom index
              if (tmpInd < 5) {
                if (matrix[tempColumnIdx][rowIdx + 1].shoe == "") {
                  tempColumnIdx--;
                  rowIdx++;
                  tmpInd = rowIdx;
                }
              }
            }
          }
          if (rowIdx < tmpInd) rowIdx++; // increment row index
        }
        prevShoe = shoe; // set current shoe as prev
        sameShoeCnt++; // same Shoe
      });
      return matrix;
    },
  },
};
</script>

<style type="text/css"></style>
