<template>
<b-row class="betting-details roominfo bar_row2">
    <betting-history ref="modalName" />
    <b-col cols="3" class="r_col1">
        <b-row class="i_room_info ml-0">
            <b-col cols="5" class="i_room_label">

                <span class="i_rooom_label">
                    {{ $t('betHistory.table' )}}
                    <span id="id_roomorder">{{currentRoom.roomno}}</span>
                </span>
            </b-col>
            <b-col cols="7" class="i_room_content">
                <span class="i_rooom_label">
                    {{ $t('betHistory.round' )}}
                    <span id="id_roomno">{{ (currentRoom.roomorder).slice((currentRoom.roomorder).length - 5) + $t('game.round') }}</span>
                </span>
            </b-col>
        </b-row>
        <b-row class="pt-1 ml-0" data-view="1">
            <b-button class="betting-room-button" variant="secondary" @click="sidebar()"> {{ $t('game.changeTable' )}}</b-button>
            <move-lobby :isPanelOpen='isPanelOpen' @sidebar="sidebar"></move-lobby>
        </b-row>
    </b-col>
    <b-col cols="4" class="r_col2 game-summ">
        <b-row>
            <b-col cols="5">
                <span class="roomin_r roomin_r1" v-if="gameType != 'DT'">
                    <div class="rred"></div>{{ $t('game.banker')}}
                </span>
                <span class="roomin_r roomin_r1" v-if="gameType == 'DT'">
                    <div class="rred"></div>{{ $t('game.dragon')}}
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r banker" id="divCountingBanker">{{ totalBankerBet | currency}}</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="5">
                <span class="roomin_r roomin_r2" v-if="gameType != 'DT'">
                    <div class="rblue"></div>{{ $t('game.player')}}
                </span>
                <span class="roomin_r roomin_r2" v-if="gameType == 'DT'">
                    <div class="rblue"></div>{{ $t('game.tiger')}}
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r player" id="divCountingPlayer">{{ totalPlayerBet | currency}}</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="5">
                <span class="roomin_r roomin_r3">
                    <div class="rgreen"></div>{{ $t('game.tie')}}
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r tie" id="divCountingTie">{{ totalTieBet | currency}}</span>
            </b-col>
        </b-row>
        <b-row v-if="gameType != 'DT'">
            <b-col cols="5">
                <span class="roomin_r roomin_r4">
                    <div class="rred"></div>B.P
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r bankerp" id="divCountingBankerPair">{{ totalPlayerPairBet | currency}}</span>
            </b-col>
        </b-row>
        <b-row v-if="gameType != 'DT'">
            <b-col cols="5">
                <span class="roomin_r roomin_r5">
                    <div class="rblue"></div>P.P
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r playerp" id="divCountingPlayerPair">{{ totalBankerPairBet | currency}}</span>
            </b-col>
        </b-row>
    </b-col>
    <!-- Levels -->
    <b-col cols="5" class="r_col3 text-right game-summ">
        <b-row>
            <b-col cols="5">
                <span class="roomin_r roomin_r1" v-if="gameType != 'DT'">
                    <div class="rred"></div>{{ $t('game.banker')}}
                </span>
                <span class="roomin_r roomin_r1" v-if="gameType == 'DT'">
                    <div class="rred"></div>{{ $t('game.dragon')}}
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r " id="idBetBanker">{{ showLimit(level.banker_min, level.banker_max) }}</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="5">
                <span class="roomin_r roomin_r2" v-if="gameType != 'DT'">
                    <div class="rblue"></div>{{ $t('game.player')}}
                </span>
                <span class="roomin_r roomin_r2" v-if="gameType == 'DT'">
                    <div class="rblue"></div>{{ $t('game.tiger')}}
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r " id="idBetPlayer">{{ showLimit(level.player_min, level.player_max) }}</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="5">
                <span class="roomin_r roomin_r3">
                    <div class="rgreen"></div>{{ $t('game.tie')}}
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r " id="idBetTie">{{ showLimit(level.tie_min, level.tie_max) }}</span>
            </b-col>
        </b-row>
        <b-row v-if="gameType != 'DT'">
            <b-col cols="5">
                <span class="roomin_r roomin_r4">
                    <div class="rred"></div>B.P
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r" id="idBetBankerPair">{{ showLimit(level.pair_min, level.pair_max) }}</span>
            </b-col>
        </b-row>
        <b-row v-if="gameType != 'DT'">
            <b-col cols="5">
                <span class="roomin_r roomin_r5">
                    <div class="rblue"></div>P.P
                </span>
            </b-col>
            <b-col cols="7">
                <span class="roomin_r1r " id="idBetPlayerPair">{{ showLimit(level.pair_min, level.pair_max) }}</span>
            </b-col>
        </b-row>
    </b-col>
</b-row>
</template>

<script>
import BettingHistory from '@/views/common/pc/BettingHistory';
import MoveLobby from '../game/MoveLobby'
export default {
    name: 'GameRoomHeader',
    components: {
        BettingHistory,
        MoveLobby
    },
    data() {
        return {
            isPanelOpen: false,
        }
    },
    props: {
        gameType: {
            required: true,
            type: String
        },
        totalPlayerBet: {
            required: true,
            type: Number
        },
        totalBankerBet: {
            required: true,
            type: Number
        },
        totalTieBet: {
            required: true,
            type: Number
        },
        totalBankerPairBet: {
            required: true,
            type: Number
        },
        totalPlayerPairBet: {
            required: true,
            type: Number
        },
    },
    watch: {
        isPanelOpen: function (val) {
            this.isPanelOpen = val;
        },
    },
    computed: {
        currentRoom() {
            return this.$store.state.gameModule.currentRoom
        },
        level() {
            return this.$store.getters['gameModule/getCurrentLevelLimit']
        },
    },
    methods: {
        sidebar() {
            this.isPanelOpen = !this.isPanelOpen
        },
        showLimit(min, max) {

            const currencyFilter = this.$options.filters.currency

            return `${currencyFilter(min)} - ${currencyFilter(max)}`
        },
        betAmount(position) {
            let amount = 0,
                bettings = []

            bettings = this.bettings;
            bettings.forEach((key, item) => {
                switch (bettings[item].betpos) {
                    case position:
                        amount = bettings[item].betamt;
                        break;
                }
            })

            return amount;
        }
    }
}
</script>
