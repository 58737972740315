<!--=========================================================================================
  File Name: ShoeArea.vue
  Description: Shoe Area Component
==========================================================================================-->
<template>
  <div
    class="row shoe-area"
    style="background-color: #f6f6f6;height: 28vh;overflow:hidden"
    ref="shoe-area-ref"
  >
    <div
      class="col-4 rm-padding grid-x grid-x-repeat-pcg"
      style="height:100%;overflow:hidden;border-right: 1px solid #0000003d"
    >
      <result-road
        :shoeResults="currentRoom.shoe"
        :gameType="gameType"
        :predict="predictS"
        :hidePredict="hidePredict"
      />
      <div
        class="bead_road_text"
        style="
             font-size: 3.25vh;
             font-weight: bold;
             position: absolute;
             color: #0000003d;
             bottom:0px;
             right:10px;
         "
      >
        BEAD ROAD
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div
          class="col-12 rm-padding grid-x grid-x-repeat-big-pcg"
          style="overflow:hidden;height:14.6vh;border-bottom: 1px solid #0000003d"
        >
          <div
            class="big_road_text"
            style="
                    font-size: 3.25vh;
                    font-weight: bold;
                    position: absolute;
                    color: #0000003d;
                    bottom: 0;
                    right: 13%;

                "
          >
            BIG ROAD
          </div>
          <result-big-road
            :shoeResults="currentRoom.shoe"
            :predict="predictS"
            :hidePredict="hidePredict"
          />
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 rm-padding grid-x grid-x-repeat-big-pcg"
          style="overflow:hidden;height:7.3vh;border-bottom: 1px solid #0000003d;"
        >
          <div
            class="big_eye_text"
            style="
                    font-size: 3.25vh;
                    font-weight: bold;
                    position: absolute;
                    color: #0000003d;
                    bottom: 0;
                    right: 13%;
                "
          >
            BIG EYE ROAD
          </div>
          <result-big-eye-road
            :shoeResults="currentRoom.shoe"
            :predict="predictS"
            :hidePredict="hidePredict"
          />
        </div>
      </div>
      <div class="row">
        <div
          class="col-6 rm-padding grid-x grid-x-repeat-big-pcg"
          style="overflow:hidden;height:6.2vh;border-right: 1px solid #0000003d;padding-top: 0px;"
        >
          <div
            class="small_road_text"
            style="
                    font-size: 3.25vh;
                    font-weight: bold;
                    position: absolute;
                    color: #0000003d;
                    bottom: 0;
                    right: 13%;
                "
          >
            SMALL ROAD
          </div>
          <result-small-road
            :shoeResults="currentRoom.shoe"
            :predict="predictS"
            :hidePredict="hidePredict"
          />
        </div>
        <div
          class="col-6 rm-padding grid-x grid-x-repeat-big-pcg"
          style="overflow:hidden;height:6.2vh;padding-top: 0px;"
        >
          <div
            class="cockroach_text"
            style="
                    font-size: 3.25vh;
                    font-weight: bold;
                    position: absolute;
                    color: #0000003d;
                    bottom: 0;
                    right: 13%;
                    "
          >
            COCKROACH ROAD
          </div>
          <result-cockroach-road
            :shoeResults="currentRoom.shoe"
            :predict="predictS"
            :hidePredict="hidePredict"
          />
        </div>
      </div>
    </div>
    <div class="col-2 pt-3 pb-3" style="padding-top: 0rem !important;">
      <div class="row h-0" style="height:100%;">
        <div class="col-6">
          <div class="container-count">
            <!-- Total Shoe -->
            <div class="container-box">
              <div class="row">
                <div class="col-6">
                  <b-badge class="badge-count" variant="secondary">#</b-badge>
                </div>
                <div class="col-6">
                  <div>
                    <h4>
                      <span class="center all-count">{{ totalShoe }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- Banker Shoe -->
            <div class="container-box">
              <div class="row">
                <div class="col-6">
                  <!-- <bead-road :shoe="'a'" /> -->
                  <b-badge
                    class="badge-count"
                    variant="danger"
                    v-if="gameType != 'DT'"
                    >B</b-badge
                  >
                  <b-badge
                    class="badge-count"
                    variant="danger"
                    v-if="gameType == 'DT'"
                    >Dr</b-badge
                  >
                </div>
                <div class="col-6">
                  <div>
                    <h4>
                      <span class="b-cnt center">{{ bankerShoe }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- Player Shoe -->
            <div class="container-box">
              <div class="row">
                <div class="col-6">
                  <b-badge
                    class="badge-count"
                    variant="primary"
                    v-if="gameType != 'DT'"
                    >P</b-badge
                  >
                  <b-badge
                    class="badge-count"
                    variant="primary"
                    v-if="gameType == 'DT'"
                    >Tr</b-badge
                  >
                </div>
                <div class="col-6">
                  <div>
                    <h4>
                      <span class="p-cnt center">{{ playerShoe }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tie Shoe -->
            <div class="container-box">
              <div class="row">
                <div class="col-6">
                  <!-- <bead-road :shoe="'i'" /> -->
                  <b-badge class="badge-count" variant="success">T</b-badge>
                </div>
                <div class="col-6">
                  <div>
                    <h4>
                      <span class="t-cnt center">{{ tieShoe }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- Banker Pair Shoe -->
            <div class="container-box" v-if="gameType != 'DT'">
              <div class="row">
                <div class="col-6">
                  <b-badge class="badge-count" variant="secondary">BP</b-badge>
                </div>
                <div class="col-6">
                  <div>
                    <h4>
                      <span class="bp-span center">{{ bankerPairShoe }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- Player Pair Shoe -->
            <div class="container-box" v-if="gameType != 'DT'">
              <div class="row">
                <div class="col-6">
                  <b-badge class="badge-count" variant="secondary">PP</b-badge>
                </div>
                <div class="col-6">
                  <div>
                    <h4>
                      <span class="pp-span center">{{ playerPairShoe }}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row shoe-predict sp-banker" @click="predictShoe('e')">
            <div class="col-12">
              <span class="p-span" v-if="gameType != 'DT'">{{
                $t("shoeArea.banker")
              }}</span>
              <span class="p-span" v-if="gameType == 'DT'">{{
                $t("shoeArea.dragon")
              }}</span>
            </div>
            <div class="col-12">
              <span>NEXT</span>
            </div>
            <div cols="12" class="sp-bp-cntnr" style="width:100% !important">
              <div class="row player-shoe-div">
                <div class="col-4 predict-bead">
                  <bead-road
                    :shoe="this.bpredictBigEye + '-big-eye-road'"
                    style="width:1vw"
                  />
                </div>
                <div class="col-4 predict-bead">
                  <bead-road
                    :shoe="this.bpredictSmall + '-small-road'"
                    style="width:1vw"
                  />
                </div>
                <div class="col-4 predict-bead">
                  <bead-road
                    :shoe="this.bpredictCockRoach + '-cockroach-road'"
                    style="width:1vw"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row  shoe-predict sp-player" @click="predictShoe('a')">
            <div class="col-12">
              <span class="p-span" v-if="gameType != 'DT'">{{
                $t("shoeArea.player")
              }}</span>
              <span class="p-span" v-if="gameType == 'DT'">{{
                $t("shoeArea.tiger")
              }}</span>
            </div>
            <div class="col-12">
              <span>NEXT</span>
            </div>
            <div cols="12" class="sp-bp-cntnr" style="width:100% !important">
              <div class="row player-shoe-div">
                <div class="col-4 predict-bead">
                  <bead-road
                    :shoe="this.ppredictBigEye + '-big-eye-road'"
                    style="width:1vw"
                  />
                </div>
                <div class="col-4 predict-bead">
                  <bead-road
                    :shoe="this.ppredictSmall + '-small-road'"
                    style="width:1vw"
                  />
                </div>
                <div class="col-4 predict-bead">
                  <bead-road
                    :shoe="this.ppredictCockRoach + '-cockroach-road'"
                    style="width:1vw"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultRoad from "@/components/baccarat/roads/gameroom/ResultRoad";
import ResultBigRoad from "@/components/baccarat/roads/gameroom/ResultBigRoad";
import ResultBigEyeRoad from "@/components/baccarat/roads/gameroom/ResultBigEyeRoad";
import ResultSmallRoad from "@/components/baccarat/roads/gameroom/ResultSmallRoad";
import ResultCockroachRoad from "@/components/baccarat/roads/gameroom/ResultCockroachRoad";
import BeadRoad from "@/components/baccarat/roads/BeadRoad";

export default {
  name: "ShoeArea",
  components: {
    BeadRoad,
    ResultRoad,
    ResultBigRoad,
    ResultBigEyeRoad,
    ResultSmallRoad,
    ResultCockroachRoad,
  },
  props: {
    gameType: { required: true, type: String },
    predictS: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      ppredictBigEye: "",
      ppredictSmall: "",
      ppredictCockRoach: "",
      bpredictBigEye: "",
      bpredictSmall: "",
      bpredictCockRoach: "",
      road: [],
      bigRoad: [],
      bigEyeRoad: [],
      smallRoad: [],
      cockroachRoad: [],
      hidePredict: true,
    };
  },
  methods: {
    predictShoe(val) {
      this.hidePredict = true;
      this.predictS = val;
      setTimeout(() => {
        this.hidePredict = false;
      }, 2000);
    },
  },
  mounted: function() {
    //e = blue - player
    //a = red - banker
    (this.ppredictBigEye = this.predictShoe3 == "b" ? "e" : "a"),
      (this.ppredictSmall = this.predictShoe4 == "b" ? "e" : "a"),
      (this.ppredictCockRoach = this.predictShoe5 == "b" ? "e" : "a"),
      (this.bpredictBigEye = this.predictShoe3 != "b" ? "e" : "a"),
      (this.bpredictSmall = this.predictShoe4 != "b" ? "e" : "a"),
      (this.bpredictCockRoach = this.predictShoe5 != "b" ? "e" : "a");
  },
  computed: {
    currentRoom() {
      return this.$store.state.gameModule.currentRoom;
    },
    totalShoe() {
      return this.currentRoom.shoe.length || 0;
    },
    bankerShoe() {
      return (
        this.currentRoom.shoe.split("").filter((s) => s == "a").length || 0
      );
    },
    playerShoe() {
      return (
        this.currentRoom.shoe.split("").filter((s) => s == "e").length || 0
      );
    },
    tieShoe() {
      return (
        this.currentRoom.shoe.split("").filter((s) => s == "i").length || 0
      );
    },
    bankerPairShoe() {
      return (
        this.currentRoom.shoe
          .split("")
          .filter((s) => s == "c" || s == "d" || s == "g" || s == "h").length ||
        0
      );
    },
    playerPairShoe() {
      return (
        this.currentRoom.shoe
          .split("")
          .filter((s) => s == "b" || s == "d" || s == "f" || s == "h").length ||
        0
      );
    },
    predictShoe3: function() {
      var str = this.$store.state.gameModule.currentRoom.shoe;
      var bigeyecnt = this.$store.state.gameModule.bigeyeroadcount;
      var finalPanel;

      var tmpStr = "";
      for (var i = 0, len = str.length; i <= len; i++) {
        if (
          str.charAt(i) != "i" &&
          str.charAt(i) != "j" &&
          str.charAt(i) != "k" &&
          str.charAt(i) != "l"
        ) {
          if (
            str.charAt(i) == "a" ||
            str.charAt(i) == "b" ||
            str.charAt(i) == "c" ||
            str.charAt(i) == "d"
          )
            tmpStr = tmpStr + "r";

          if (
            str.charAt(i) == "e" ||
            str.charAt(i) == "f" ||
            str.charAt(i) == "g" ||
            str.charAt(i) == "h"
          )
            tmpStr = tmpStr + "b";
        }
      }

      var tmpStr1 = "";
      for (var x = 0, len1 = tmpStr.length; x <= len1; x++) {
        var prevChar = "";
        if (x == 0) prevChar = "";
        else prevChar = tmpStr.charAt(i - 1);

        var curChar = "";
        if (prevChar != tmpStr.charAt(x)) {
          curChar = "||" + tmpStr.charAt(x);
        } else {
          curChar = tmpStr.charAt(x);
        }
        tmpStr1 = tmpStr1 + curChar;
      }

      var tmpArr = tmpStr1.split("||");
      //var pnl3Str = "";

      for (var key in tmpArr) {
        if (key == 2) {
          if (tmpArr[key].length > 1) {
            var tmpBR = false;
            for (var z = 0, len2 = tmpArr[key].length - 1; z <= len2; z++) {
              if (z > 0) {
                if (tmpArr[key - 1].length > z) {
                  finalPanel = "r";
                } else {
                  if (!tmpBR) {
                    finalPanel = "b";
                    tmpBR = true;
                  } else {
                    finalPanel = "r";
                  }
                }
              }
            }
          }
        }

        if (key > 2) {
          for (var y = 0, len3 = tmpArr[key].length - 1; y <= len3; y++) {
            if (y == 0) {
              if (key >= 3) {
                if (tmpArr[key - 2].length == tmpArr[key - 1].length) {
                  finalPanel = "r";
                } else {
                  finalPanel = "b";
                  tmpBR = false;
                }
              } else {
                if (bigeyecnt == 0) {
                  finalPanel = "r";
                  tmpBR = false;
                } else {
                  finalPanel = "b";
                  tmpBR = false;
                }
              }
            }
            if (y > 0) {
              if (tmpArr[key - 1].length == y) tmpBR = false;

              if (tmpArr[key - 1].length > y) {
                if (tmpBR) {
                  finalPanel = "b";
                  tmpBR = false;
                } else {
                  finalPanel = "r";
                }
              } else {
                if (!tmpBR) {
                  finalPanel = "b";
                  tmpBR = true;
                } else {
                  finalPanel = "r";
                }
              }
            }
          }
        }
      }

      return finalPanel;
    },
    predictShoe4: function() {
      var str = this.$store.state.gameModule.currentRoom.shoe;
      var smallroadcnt = this.$store.state.gameModule.smallroadcount;
      var finalPanel;

      var tmpStr = "";
      for (var i = 0, len = str.length; i <= len; i++) {
        if (
          str.charAt(i) != "i" &&
          str.charAt(i) != "j" &&
          str.charAt(i) != "k" &&
          str.charAt(i) != "l"
        ) {
          if (
            str.charAt(i) == "a" ||
            str.charAt(i) == "b" ||
            str.charAt(i) == "c" ||
            str.charAt(i) == "d"
          )
            tmpStr = tmpStr + "r";
          if (
            str.charAt(i) == "e" ||
            str.charAt(i) == "f" ||
            str.charAt(i) == "g" ||
            str.charAt(i) == "h"
          )
            tmpStr = tmpStr + "b";
        }
      }

      var tmpStr1 = "";
      for (var x = 0, len2 = tmpStr.length; x <= len2; x++) {
        var prevChar = "";
        if (x == 0) prevChar = "";
        else prevChar = tmpStr.charAt(x - 1);

        var curChar = "";
        if (prevChar != tmpStr.charAt(x)) {
          curChar = "||" + tmpStr.charAt(x);
        } else {
          curChar = tmpStr.charAt(x);
        }
        tmpStr1 = tmpStr1 + curChar;
      }

      var tmpArr = tmpStr1.split("||");
      for (var key in tmpArr) {
        if (key == 3) {
          if (tmpArr[key].length > 0) {
            var tmpBR = false;
            for (var y = 0, len3 = tmpArr[key].length - 1; y <= len3; y++) {
              if (y > 0) {
                if (tmpArr[key - 2].length > y) {
                  finalPanel = "r";
                } else {
                  if (!tmpBR) {
                    finalPanel = "b";
                    tmpBR = true;
                  } else {
                    finalPanel = "r";
                  }
                }
              }
            }
          }
        }

        if (key > 3) {
          for (var z = 0, len4 = tmpArr[key].length - 1; z <= len4; z++) {
            if (z == 0) {
              if (key > 3) {
                if (tmpArr[key - 3].length == tmpArr[key - 1].length) {
                  finalPanel = "r";
                } else {
                  finalPanel = "b";
                  tmpBR = false;
                }
              } else {
                if (smallroadcnt == 0) {
                  finalPanel = "r";
                  tmpBR = false;
                } else {
                  finalPanel = "b";
                  tmpBR = false;
                }
              }
            }
            if (z > 0) {
              if (tmpArr[key - 2].length == z) tmpBR = false;
              if (tmpArr[key - 2].length > z) {
                if (tmpBR) {
                  finalPanel = "b";
                  tmpBR = false;
                } else {
                  finalPanel = "r";
                }
              } else {
                if (!tmpBR) {
                  finalPanel = "b";
                  tmpBR = true;
                } else {
                  finalPanel = "r";
                }
              }
            }
          }
        }
      }

      return finalPanel;
    },
    predictShoe5: function() {
      var str = this.$store.state.gameModule.currentRoom.shoe;
      var smallroadcnt = this.$store.state.gameModule.croadcount;

      var finalPanel;
      var tmpStr = "";
      for (var i = 0, len = str.length; i <= len; i++) {
        if (
          str.charAt(i) != "i" &&
          str.charAt(i) != "j" &&
          str.charAt(i) != "k" &&
          str.charAt(i) != "l"
        ) {
          if (
            str.charAt(i) == "a" ||
            str.charAt(i) == "b" ||
            str.charAt(i) == "c" ||
            str.charAt(i) == "d"
          )
            tmpStr = tmpStr + "r";
          if (
            str.charAt(i) == "e" ||
            str.charAt(i) == "f" ||
            str.charAt(i) == "g" ||
            str.charAt(i) == "h"
          )
            tmpStr = tmpStr + "b";
        }
      }

      var tmpStr1 = "";

      for (var x = 0, len1 = tmpStr.length; x <= len1; x++) {
        var prevChar = "";
        if (x == 0) prevChar = "";
        else prevChar = tmpStr.charAt(x - 1);

        var curChar = "";
        if (prevChar != tmpStr.charAt(x)) {
          curChar = "||" + tmpStr.charAt(x);
        } else {
          curChar = tmpStr.charAt(x);
        }
        tmpStr1 = tmpStr1 + curChar;
      }

      var tmpArr = tmpStr1.split("||");
      var tmpBR;
      for (var key in tmpArr) {
        if (key == 4) {
          if (tmpArr[key].length >= 1) {
            tmpBR = false;
            for (var y = 0, len2 = tmpArr[key].length - 1; y <= len2; y++) {
              if (y > 0) {
                if (tmpArr[key - 3].length > y) {
                  finalPanel = "r";
                } else {
                  if (!tmpBR) {
                    finalPanel = "b";
                    tmpBR = true;
                  } else {
                    finalPanel = "r";
                  }
                }
              }
            }
          }
        }

        if (key > 4) {
          for (var z = 0, len3 = tmpArr[key].length - 1; z <= len3; z++) {
            if (z == 0) {
              if (key > 4) {
                if (tmpArr[key - 4].length == tmpArr[key - 1].length) {
                  finalPanel = "r";
                } else {
                  finalPanel = "b";
                  tmpBR = false;
                }
              } else {
                if (smallroadcnt == 0) {
                  finalPanel = "r";
                  tmpBR = false;
                } else {
                  finalPanel = "b";
                  tmpBR = false;
                }
              }
            }
            if (z > 0) {
              if (tmpArr[key - 3].length == z) tmpBR = false;

              if (tmpArr[key - 3].length > z) {
                if (tmpBR) {
                  finalPanel = "b";
                  tmpBR = false;
                } else {
                  finalPanel = "r";
                }
              } else {
                if (!tmpBR) {
                  finalPanel = "b";
                  tmpBR = true;
                } else {
                  finalPanel = "r";
                }
              }
            }
          }
        }
      }

      return finalPanel;
    },
  },
  watch: {
    //e = blue - player
    //a = red - banker
    predictShoe3: {
      handler() {
        this.ppredictBigEye = this.predictShoe3 == "b" ? "e" : "a";
        this.bpredictBigEye = this.predictShoe3 != "b" ? "e" : "a";
      },
      deep: true,
    },
    predictShoe4: {
      handler() {
        this.ppredictSmall = this.predictShoe4 == "b" ? "e" : "a";
        this.bpredictSmall = this.predictShoe4 != "b" ? "e" : "a";
      },
      deep: true,
    },
    predictShoe5: {
      handler() {
        this.ppredictCockRoach = this.predictShoe5 == "b" ? "e" : "a";
        this.bpredictCockRoach = this.predictShoe5 != "b" ? "e" : "a";
      },
      deep: true,
    },
  },
};
</script>

<style>
.btn-shoe {
  position: absolute;
  bottom: 0px;
  font-size: 1.5vh;
  color: #beae93;
}

.player-shoe-div {
  background-color: white;
  height: 35px;
  position: relative;
  margin: 0 auto;
  padding-top: 7px;
  border-radius: 5px;
  text-align: center;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.predict-bead {
  max-width: 100% !important;
  flex: 1;
  padding-right: 0px;
  padding-left: 0px;
}

.sp-banker,
.sp-player {
  cursor: pointer;
}

.sp-player .p-span,
.sp-banker .b-span {
  font-size: 1vw;
}

.rs-ctnr-gr {
  height: 100%;
}

.rm-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.container-count {
  display: flex;
  /* add -webkit and -ms prefixes yourself */
  flex-direction: column;
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.container-box {
  flex-grow: 1;
  margin: 1px;
}
</style>
