<template>
  <div class="sidebar">
    <!-- <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div> -->
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <b-container>
          <b-row>
            <b-col cols="12">
              <!--p style="text-align:center"><img class="lobby-close-button" src="/assets/images/gameroom/close-lobby.png" @click="isPanelOpen = !isPanelOpen"></p-->
              <p style="text-align:center">
                <b-button @click="closePanel()">{{
                  $t("uiLabels.close")
                }}</b-button>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="room-list" style="overflow:hidden;">
                <div v-for="(room, idx) in gameRooms" :key="idx">
                  <span v-if="!room.isLocked">
                    <move-lobby-shoe :room="room"></move-lobby-shoe>
                  </span>
                  <span v-if="room.isLocked && room.shoe == ''">
                    <move-lobby-closed :room="room"></move-lobby-closed>
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </transition>
  </div>
</template>
<script>
import MoveLobbyShoe from "@/components/baccarat/roads/MoveLobbyShoe";
import MoveLobbyClosed from "@/components/baccarat/roads/MoveLobbyClosed";

export default {
  components: {
    MoveLobbyShoe,
    MoveLobbyClosed,
  },
  methods: {
    // closeSidebarPanel: mutations.toggleNav
    closePanel() {
      this.$emit("sidebar");
    },
  },
  watch: {
    isPanelOpen: function(val) {
      this.isPanelOpen = val;
    },
  },
  props: {
    isPanelOpen: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    // isPanelOpen() {
    //     return store.isNavOpen
    // }
    gameRooms() {
      let rooms = []
      switch('') {
          case '':  rooms = this.$store.state.gameModule.game.rooms; break;
          case 'BAC': rooms = this.$store.state.gameModule.game.rooms.filter(r => { return r.type == 'BAC' }); break;
          case 'SBAC': rooms = this.$store.state.gameModule.game.rooms.filter(r => { return r.type == 'SBAC' }); break;
          case 'DT': rooms = this.$store.state.gameModule.game.rooms.filter(r => { return r.type == 'DT' }); break;
      }
      return rooms
    },
  },
};
</script>
<style>
.lobby-close-button {
  height: 7vh;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 100ms ease-in 0s;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: #130f40;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 3rem 20px 2rem 20px;
  width: 530px;
  font-size: 16px;
}

.openSidebar {
  right: 491px !important;
  position: absolute !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.sidebar-panel::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.sidebar-panel::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.sidebar-panel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #202020;
}
</style>
