<!--=========================================================================================
  File Name: ResultBigEyeRoad.vue
  Description: Result Big Road Eye Component
==========================================================================================-->
<template>
  <div class="content grid-lines" style="padding-top: 0vh">
    <div class="carfbig-road" ref="shoe-tbl">
      <img
        @click="scrollTarget('left')"
        v-if="showLeftArrow"
        src="/assets/images/gamelobby/btn_hisprev.png"
        alt="Prev Button"
        class="big-road-prev-gr"
      />
      <div
        class="rs-ctnr d-flex flex-row grid-y grid-y-repeat-big-pcg"
        v-bind:style="{ width: shoeW }"
      >
        <div
          class="flex-column"
          v-for="(shoeM, x) in shoeMatrixBigEye"
          :key="x"
        >
          <div
            v-for="(shoe, i) in shoeM"
            v-bind:key="shoe.index + '-' + i"
            class="big-road-shoe"
            style="height:1.3vh;width: 1.3vh;"
            v-bind:class="[
              {
                animate_latest:
                  x == shoeMatrixBigEye.length - 1 &&
                  shoe.shoe != '' &&
                  i == $getLasttIndex(shoeMatrixBigEye, x) &&
                  !hidePredict && predict.length <= 0,
              },
              {
                animate_predict:
                  x == shoeMatrixBigEye.length - 1 &&
                  shoe.shoe != '' &&
                  i == $getLasttIndex(shoeMatrixBigEye, x) &&
                  hidePredict,
              },
              shoe.shoe + '-big-eye-road',
            ]"
          ></div>
        </div>
      </div>
      <img
        @click="scrollTarget('right')"
        v-if="showRightArrow"
        src="/assets/images/gamelobby/btn_hisnext.png"
        alt="Next Button"
        class="big-road-next-gr"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "ResultBigEyeRoad",
  components: {},
  data() {
    return {
      showRightArrow: false,
      showLeftArrow: false,
      width: 0,
      window: {
        width: 0,
        height: 0,
      },
      scrollW: 0,
      shoeW: "1000px",
    };
  },
  props: {
    shoeResults: { required: true, type: String },
    predict: {
      required: false,
      type: String,
    },
    hidePredict: {
      required: false,
      type: Boolean,
    },
  },
  watch: {
    shoeResults: {
      handler() {
        this.$store.commit(
          "gameModule/SET_BIGEYEROAD_COUNT",
          this.shoeResults.length
        );
      },
    },
  },
  computed: {
    shoeMatrix() {
      let matrix = [],
        prevShoe = "",
        columnIdx = -1,
        rowIdx = 1,
        sameShoeCnt = 0,
        tempColumnIdx = -1,
        tmpInd = 5,
        tieCnt = 0,
        shoeR = "";
      const tieShoe = ["i", "j", "k", "l"];

      if (this.predict != undefined && this.hidePredict) {
        shoeR = this.shoeResults + this.predict;
      } else {
        shoeR = this.shoeResults;
      }
      shoeR.split("").forEach((shoe) => {
        if (!(tieShoe.indexOf(shoe) !== -1)) {
          // If shoe is not tie
          if (!(this.$prevShoeArr(prevShoe).indexOf(shoe) !== -1)) {
            // create new column
            if (matrix[columnIdx + 1]) {
              // check if there's an over shoe, overwrite 1st column
              matrix[columnIdx + 1][0] = { shoe, count: 0 };
            } else {
              matrix.push([
                { shoe, count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
              ]);
            }
            rowIdx = 1; // reset row index to 1 (next vertical shoe)
            sameShoeCnt = 0; // reset same shoe count
            columnIdx++; // increment column index
            tempColumnIdx = columnIdx;
            tieCnt = 0;
          } else {
            // normal add
            if (sameShoeCnt > rowIdx) {
              matrix.push([
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
              ]);
              tempColumnIdx++;
              matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 };
              if (tmpInd < 5) {
                //
                if (matrix[tempColumnIdx][rowIdx + 1].shoe == "") {
                  tempColumnIdx--;
                  rowIdx++;
                  tmpInd = rowIdx;
                }
              }
            } else {
              if (
                matrix[tempColumnIdx] &&
                matrix[tempColumnIdx][rowIdx].shoe == ""
              ) {
                matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 };
              } else {
                tmpInd = rowIdx - 1;
                // check if the matrix has enough length to handle dragon tail
                if (matrix.length < tempColumnIdx) {
                  matrix.push([
                    { shoe: "", count: 0 },
                    { shoe: "", count: 0 },
                    { shoe: "", count: 0 },
                    { shoe: "", count: 0 },
                    { shoe: "", count: 0 },
                    { shoe: "", count: 0 },
                  ]);
                }

                matrix.push([
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                ]);
                tempColumnIdx++;
                rowIdx--;
                matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 };

                // Always check the bottom index
                if (tmpInd < 5) {
                  if (matrix[tempColumnIdx][rowIdx + 1].shoe == "") {
                    tempColumnIdx--;
                    rowIdx++;
                    tmpInd = rowIdx;
                  }
                }
              }
            }
            if (rowIdx < tmpInd) rowIdx++; // increment row index
          }
          prevShoe = shoe; // set current shoe as prev
          sameShoeCnt++; // same Shoe
          tieCnt = 0; // reset tie counting.
        } else {
          tieCnt++;
          if (rowIdx > -1 && tempColumnIdx > -1) {
            matrix[tempColumnIdx][rowIdx - 1] = {
              shoe: matrix[tempColumnIdx][rowIdx - 1].shoe,
              count: tieCnt,
            };
          }
        }
      });
      return matrix;
    },
    shoeMatrixBigEye() {
      let bigRoad = this.shoeMatrix;
      let bigEyedArr = [];

      // Bigroad array
      bigRoad.forEach((shoeArr, k) => {
        if (k > 1) {
          shoeArr.forEach((item, i) => {
            if (item.shoe != "") {
              var res = this.$shoePredict(bigRoad, k, i);
              bigEyedArr.push(res);
            }
          });
        }
      });

      // Initialized the bigeyeroad data
      let matrix = [],
        prevShoe = "",
        columnIdx = -1,
        rowIdx = 1,
        sameShoeCnt = 0,
        tempColumnIdx = -1,
        tmpInd = 5;

      bigEyedArr.forEach((shoe) => {
        if (!(this.$prevShoeArr(prevShoe).indexOf(shoe) !== -1)) {
          // create new column
          if (matrix[columnIdx + 1]) {
            // check if there's an over shoe, overwrite 1st column
            matrix[columnIdx + 1][0] = { shoe, count: 0 };
          } else {
            matrix.push([
              { shoe, count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
            ]);
          }
          rowIdx = 1; // reset row index to 1 (next vertical shoe)
          sameShoeCnt = 0; // reset same shoe count
          columnIdx++; // increment column index
          tempColumnIdx = columnIdx;
        } else {
          // normal add
          if (sameShoeCnt > rowIdx) {
            matrix.push([
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
              { shoe: "", count: 0 },
            ]);
            tempColumnIdx++;
            matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 };
            if (tmpInd < 5) {
              //
              if (matrix[tempColumnIdx][rowIdx + 1].shoe == "") {
                tempColumnIdx--;
                rowIdx++;
                tmpInd = rowIdx;
              }
            }
          } else {
            if (
              matrix[tempColumnIdx] &&
              matrix[tempColumnIdx][rowIdx].shoe == ""
            ) {
              matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 };
            } else {
              tmpInd = rowIdx - 1;
              // check if the matrix has enough length to handle dragon tail
              if (matrix.length < tempColumnIdx) {
                matrix.push([
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                  { shoe: "", count: 0 },
                ]);
              }

              matrix.push([
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
                { shoe: "", count: 0 },
              ]);
              tempColumnIdx++;
              rowIdx--;
              matrix[tempColumnIdx][rowIdx] = { shoe, count: 0 };

              // Always check the bottom index
              if (tmpInd < 5) {
                if (matrix[tempColumnIdx][rowIdx + 1].shoe == "") {
                  tempColumnIdx--;
                  rowIdx++;
                  tmpInd = rowIdx;
                }
              }
            }
          }
          if (rowIdx < tmpInd) rowIdx++; // increment row index
        }
        prevShoe = shoe; // set current shoe as prev
        sameShoeCnt++; // same Shoe
      });
      return matrix;
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.shoeMatrixBigEye.length > 60) {
      this.showRightArrow = true;
      this.showLeftArrow = true;
      if (this.$refs["shoe-tbl"])
        this.$refs["shoe-tbl"].scrollLeft =
          ((this.shoeMatrixBigEye.length - 60) * this.scrollW) / 10;
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.scrollW = Math.ceil((this.window.height * 1.04) / 100);
      if (this.$refs["shoe-tbl"])
        this.$refs["shoe-tbl"].scrollLeft =
          ((this.shoeMatrixBigEye.length - 60) * this.scrollW) / 10;
    },
    scrollTarget(move) {
      if (move == "right") this.$refs["shoe-tbl"].scrollLeft += this.scrollW;
      else this.$refs["shoe-tbl"].scrollLeft -= this.scrollW;
    },
  },
};
</script>
