var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{ref:"shoe-tbl",staticClass:"bead-road grid-y-repeat-pcg"},[(_vm.showLeftArrow)?_c('img',{staticClass:"shoe-prev-gr",attrs:{"src":"/assets/images/gamelobby/btn_hisprev.png","alt":"Prev Button"},on:{"click":function($event){return _vm.scrollTarget('left')}}}):_vm._e(),_c('div',{staticClass:"rs-ctnr d-flex flex-rowgrid-y-repeat-pcg",style:({ width: _vm.shoeW })},_vm._l((_vm.shoeMatrix),function(shoeM,x){return _c('div',{key:x,staticClass:"flex-column"},_vm._l((shoeM.split('')),function(shoe,i){return _c('div',{key:i,staticStyle:{"height":"4.6vh","width":"4.56vh"}},[_c('div',{staticClass:"shoe-item shoe-text",class:[
              {
                animate_latest:
                  x == _vm.shoeMatrix.length - 1 &&
                  shoeM.split('').length - 1 == i &&
                  _vm.$getLastResultRoadtIndex(_vm.shoeMatrix, x) == i &&
                  !_vm.hidePredict && _vm.predict.length <= 0,
              },
              {
                animate_predict:
                  x == _vm.shoeMatrix.length - 1 &&
                  shoeM.split('').length - 1 == i &&
                  _vm.$getLastResultRoadtIndex(_vm.shoeMatrix, x) == i &&
                  _vm.hidePredict,
              },
              shoe ],staticStyle:{"height":"4.3vh","width":"4.3vh"}},[(_vm.gameType != 'DT')?_c('span',{staticClass:"res-span-gr"},[_vm._v(_vm._s(_vm._f("shoeText")(shoe)))]):_vm._e(),(_vm.gameType == 'DT')?_c('span',{staticClass:"res-span-gr"},[_vm._v(_vm._s(_vm._f("shoeTextDT")(shoe)))]):_vm._e()])])}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }