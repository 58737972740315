var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content grid-lines",staticStyle:{"padding-top":"0.16vh"}},[_c('div',{ref:"shoe-tbl",staticClass:"carfbig-road"},[(_vm.showLeftArrow)?_c('img',{staticClass:"big-road-prev-gr",attrs:{"src":"/assets/images/gamelobby/btn_hisprev.png","alt":"Prev Button"},on:{"click":function($event){return _vm.scrollTarget('left')}}}):_vm._e(),_c('div',{staticClass:"rs-ctnr d-flex flex-row grid-y grid-y-repeat-big-pcg",style:({ width: _vm.shoeW })},_vm._l((_vm.bigRoadMatrix),function(shoeM,x){return _c('div',{key:x,staticClass:"flex-column"},_vm._l((shoeM),function(shoe,i){return _c('div',{key:shoe.index + '-' + i,staticStyle:{"height":"2.4vh","width":"2.4vh"}},[_c('div',{staticClass:"big-road-shoe",class:[
              {
                animate_latest:
                  x == _vm.bigRoadMatrix.length - 1 &&
                  shoe.shoe != '' &&
                  i == _vm.$getLasttIndex(_vm.bigRoadMatrix, x) &&
                  !_vm.hidePredict &&
                  _vm.predict.length <= 0,
              },
              {
                animate_predict:
                  x == _vm.bigRoadMatrix.length - 1 &&
                  shoe.shoe != '' &&
                  i == _vm.$getLasttIndex(_vm.bigRoadMatrix, x) &&
                  _vm.hidePredict,
              },
              shoe.shoe + '-big-road' ],staticStyle:{"height":"2.5vh","width":"2.5vh","margin-left":"0.1vh"}},[(shoe.count > 0)?_c('span',{staticStyle:{"font-size":"1.2vh"}},[_vm._v(_vm._s(shoe.count))]):_vm._e()])])}),0)}),0),(_vm.showRightArrow)?_c('img',{staticClass:"big-road-next-gr",attrs:{"src":"/assets/images/gamelobby/btn_hisnext.png","alt":"Next Button"},on:{"click":function($event){return _vm.scrollTarget('right')}}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }