<!--=========================================================================================
  File Name: VideoPlayer.vue
  Description: Video Player Component
==========================================================================================-->
<template>
  <div class="video-player">
    <!-- Betting Timer -->
    <timer @playAudio="playAudio" :initBetFromBetArea="initBetting" />
    <!-- Zoom Button -->
    <span
      :class="['btn-refresh', { loading: this.refreshLoading }]"
      @click="videoRefresh()"
    ></span>
    <!-- Zoom Button -->
    <span class="btn-zoom" @click="videoZoom()"></span>
    <!-- Iframe Source -->
    <iframe
      frameborder="0"
      scrolling="no"
      :src="vidurl"
      :key="vidurl"
      class="video-frame"
      :style="video_zoom"
    ></iframe>
    <!-- Roomorder Info -->
    <div class="control_bg_div">
      <b-row class="control_bg">
        <b-col cols="3" class="mod-mw-20">
          <b-row>
            <span class="time-label">{{ $t("game.liveBaccarat") }}</span>
          </b-row>
          <b-row>
            <span class="time-content">{{ timestamp }}</span>
          </b-row>
        </b-col>
        <b-col cols="9" class="data_marque_div">
          <!-- <animated-number :incremental="balamount" /> -->
          <b-row>
            <div class="data_ma">
              <marquee>{{ videoText }}</marquee>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div>
      <animated-number
        :showDiffBalance="showDiffBalance"
        :winLose="winLose"
        :incremental="balamount"
      />

    </div>
  </div>
</template>

<script>
import AnimatedNumber from "@/components/common/AnimatedNumber";
import Timer from "@/components/common/Timer";

export default {
  name: "VideoPlayer",
  components: {
    Timer,
    AnimatedNumber,
  },
  mounted() {
    this.$store.commit("gameModule/SET_PREV_BALANCE", this.member.balance);
  },
  computed: {
    prevBettings() {
      return this.$store.state.betModule.prevbettings;
    },
    member() {
      return this.$store.state.gameModule.member;
    },
    currentRoom() {
      return this.$store.state.gameModule.currentRoom;
    },
    vidurl() {
      if (this.refreshLoading) return "";
      return this.$store.state.gameModule.video;
    },
    videoText() {
      let settingText = '';
      if (this.$i18n.locale == 'en-US') {
        settingText = this.$store.state.gameModule.settings.videoTextEn;
      } else if (this.$i18n.locale == 'ch-CH') {
        settingText = this.$store.state.gameModule.settings.videoTextCn;
      } else {
        settingText = this.$store.state.gameModule.settings.videoTextKr;
      }
      if (settingText != '') {
        return settingText;
      } else {
        return  this.$t("game.video.marqueeText");
      }
    }
  },
  props: {
    initBetting: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      hasPrevBet: false,
      timestamp: "",
      isZoomed: false,
      refreshLoading: false,
      videoInterval: 0,
      currentBalance: 0,
      showDiffBalance: false,
      balamount: 0,
      winLose: null,
      video_zoom: {
        'width': '100%'
      },
      fakeVid: false
    };
  },
  methods: {
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    videoZoom() {
      // Zoom depends on the room
      const roomCode = this.$route.params.code;

      if (this.isZoomed == false) {
        this.isZoomed = true;
        if (roomCode === '88') {
          this.video_zoom = {
            'width': '140%',
            'margin-left': '-22%',
          }
        } else if (roomCode === 'EVO') {
          this.video_zoom = {
            'width': '200%',
            'margin-left': '-50%',
          }
        } else {
          this.video_zoom = {
            'width': '300%',
            'margin-left': '-100%',
          }
        }

      } else {
        this.isZoomed = false;
        this.video_zoom = {
          'width': '100%'
        }
      }
    },
    async videoRefresh() {
      this.$socket.client.emit("video_refresh", {
        roomno: this.currentRoom.roomno,
        mobile: false,
      });

      if (!this.refreshLoading) {
        this.refreshLoading = true;
        setTimeout(() => {
          this.refreshLoading = false;
        }, 1000);
      }
    },
    playAudio(id) {
      this.$emit("playAudio", id);
    },
  },
  created() {
    this.videoInterval = setInterval(this.videoRefresh, 300000);
    setInterval(this.getNow, 1000);
  },
  watch: {

    currentRoom: {
      handler(val) {

        if (val.state == 3) {
          if(this.prevBettings.length > 0) this.hasPrevBet = true
        }

        if (val.state == 1 && val.result == null && val.initial) {

          this.balamount = 0;

          setTimeout(() => {
            
            this.$store.dispatch('betModule/refreshBalance');
            let balDiff =  parseInt(this.member.balance) - parseInt(this.member.prevBalance);

            if (parseInt(balDiff) > 0) this.winLose = true;
            else if (parseInt(balDiff) < 0) {
              balDiff = balDiff * -1;
              this.winLose = false;
            }

            if (parseInt(balDiff) > -1) {
              if (!this.initBetting && this.hasPrevBet) {
                this.showDiffBalance = true;
              }
              this.balamount = balDiff;
            }

            if (this.showDiffBalance) {
              setTimeout(() => { this.showDiffBalance = false; }, 3000);
              this.$store.commit( "gameModule/SET_PREV_BALANCE", this.member.balance);
            }

          }, 1500);
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.video-player {
  overflow: hidden;
}
.time-label {
  padding-left: 35px;
}
.time-content {
  padding-left: 5px;
}
.mod-mw-20 {
  max-width: 20%;
}
.control_bg_div {
  /* margin-top: -5px; */
  position: absolute;
  bottom: 0;
  width: 99%;
}
.data_marque_div {
  justify-content: center;
  align-items: center;
  /* display: flex; */
  padding-top: 8px;
}

.data_ma {
  width: 100%;
}

.btn-zoom {
  cursor: pointer;
  position: absolute;
  bottom: 6vh;
  right: 2vh;
  display: inline-block;
  height: 1vw;
  width: 1vw;
  background: url(/assets/images/game/button/zoom-plus.png) no-repeat;
  background-size: contain;
}

.btn-zoom:hover {
  opacity: 0.7;
}

.btn-refresh {
  cursor: pointer;
  position: absolute;
  bottom: 6vh;
  right: 6vh;
  display: inline-block;
  height: 1vw;
  width: 1vw;
  background: url(/assets/images/game/button/refresh.png) no-repeat;
  background-size: contain;
}

.btn-refresh.loading {
  cursor: progress;
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.btn-refresh:hover {
  opacity: 0.7;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.video-frame {
  margin-top: 0px;
  height: 60.5vh;
  width: 100%;
  background-color: #000;
  background-image: url("/assets/images/game/history/loading.gif");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 3vw 3vw;
}
.video-zoom {
  width: 300%;
  margin-left: -100%;
}


.animation-box {
  width: 100%;
  height: 100%;
  background-color: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 15;
}

</style>
