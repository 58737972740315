<!--=========================================================================================
  File Name: GameHeader.vue
  Description: Game Header Component
==========================================================================================-->
<template>
    <b-row class='top-head' style="height: 5vh">
        <b-col md= "1" sm="1">
            <img  src="/assets/images/gameroom/back-arrow.png" class ='top-icn-back left' @click="redirectToLobby" style="cursor: pointer">
        </b-col>
        <b-col md= "1" sm="1" style="padding-left: 0px">
        </b-col>
        <betting-history ref="modalName"/>

        <b-col cols="4" md= "10" sm="10">
            <div class="right-menu-div">
                <div class="right-menu-itms">
                  <img src="/assets/images/gameroom/lobby_button.png" class ='img img-fluid top-icn-profile left'  @click="redirectToLobby()" style="width: auto !important;height: 3.1vh !important;cursor:pointer;">
                  <move-lobby :isPanelOpen='isPanelOpen' @sidebar="sidebar"></move-lobby>
                </div>
                <div class="right-menu-itms">
                    <img src="/assets/images/gameroom/Profile_icon.png" class ='img img-fluid top-icn-profile img-height-30 left'>
                </div>
                <div class="right-menu-itms">
                    <span class="username-span left">{{ member.username }}</span>
                </div>
                <div class="right-menu-itms">
                    <img  src="/assets/images/gameroom/btn_screen1.png" class ='img img-fluid top-icn-screen img-height-30 cursor-pointer' @click="enterFullScreen" >
                </div>
                <div class="right-menu-itms">
                    <img  src="/assets/images/gameroom/btn_history.png" class ='img img-fluid top-icn-history img-height-30 cursor-pointer'  @click="$refs.modalName.openModal()">
                </div>
                <div class="right-menu-itms" v-if="muteAudio == 0">
                    <img  src="/assets/images/gameroom/btn_sound1.png" class ='img img-fluid top-icn-sound img-height-30 cursor-pointer audio-on' @click="muteAudioFn()">
                </div>
                <div class="right-menu-itms" v-if="muteAudio == 1">
                    <img  src="/assets/images/gameroom/btn_sound1.png" class ='img img-fluid top-icn-sound img-height-30 cursor-pointer audio-off' @click="muteAudioFn()">
                </div>
                <!--<div class="right-menu-itms" style="display:none;" >
                    <img  src="/assets/images/gameroom/btn_setting.png" class ='img img-fluid top-icn-setting img-height-30 cursor-pointer' >
                </div>-->
            </div>

        </b-col>
    </b-row>

</template>
<script>

import BettingHistory from '@/views/common/pc/BettingHistory';
import router from '../../routes/router';
import MoveLobby from '@/components/baccarat/game/MoveLobby'
export default {
    name: 'GameHeader',
    components: {
        BettingHistory,
        MoveLobby
    },
    mounted() {
        if (localStorage.getItem('muteAudio') === null) {
            localStorage.setItem('muteAudio', 0);
        }
        this.muteAudio = localStorage.getItem('muteAudio');
    },
    data() {
        return {
            isPanelOpen: false,
            show:false,
            langs: [
                {
                    code: 'ko-KR',
                    label: '한국어',
                    imgPath: '/assets/images/gamelobby/flags/korea.png'
                },
                {
                    code: 'en-US',
                    label: 'English',
                    imgPath: '/assets/images/gamelobby/flags/usa.png'
                },
                {
                    code: 'ch-CHINA',
                    label: '中文',
                    imgPath: '/assets/images/gamelobby/flags/china.png'
                }
            ],
            muteAudio: 0
        }
    },
    computed: {
        member() {
            return this.$store.state.gameModule.member
        }
    },
    methods: {
        sidebar() {
            this.isPanelOpen = !this.isPanelOpen
        },
        redirectToLobby() {
           // alert('100');
           //setTimeout(() => this.$router.push(`/lobby`) , 100);
            // this.$router.push(`/lobby`)
            if (router.currentRoute.path != '/lobby')
                this.$router.push(`/lobby`)

        },
        enterFullScreen() {

            let isInFullScreen = (document.fullScreenElement && document.fullScreenElement !== null) ||  (document.mozFullScreen || document.webkitIsFullScreen);

            if (isInFullScreen) this.cancelFullScreen();
            else this.requestFullScreen();

            return false;
        },
        cancelFullScreen() {

            let el = document.body;
            let requestMethod = el.cancelFullScreen||el.webkitCancelFullScreen||el.mozCancelFullScreen||el.exitFullscreen||el.webkitExitFullscreen;
            if (requestMethod) { // cancel full screen.
                requestMethod.call(el);
            }
        },
        requestFullScreen() {

            let el = document.body;
            let requestMethod = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;

            if (requestMethod) { // Native full screen.
                requestMethod.call(el);
            }
        },
        displayBetHistory() {
            this.show = !this.show;
        },
        openMoveRoom() {

        },
        muteAudioFn() {
            if (localStorage.getItem('muteAudio') === null || this.muteAudio == 0) {
                this.muteAudio = 1;
            } else {
                this.muteAudio = 0;
            }
        },

    },
    watch: {
        muteAudio(audioStatus) {
            localStorage.setItem('muteAudio', audioStatus);
        },
        isPanelOpen: function (val) {
            this.isPanelOpen = val;
        },
    }

}
</script>
<style>

    .username-span {
      font-weight: 500;
    }
    .cursor-pointer {
        cursor:pointer;
    }
    .company-logo{
        height: 50px !important;
        /* width: 150%; */
        padding: 5px;
    }
    .right-menu-div {
        right: 0;
        position: absolute;
        bottom:6px;
    }
    .right-menu-itms{
        float: left;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
    }
    .img-height-30 {
        height: 3vh;
    }

    .img-height-30 {
        height: 3.1vh !important;
        width: 3.2vh !important;
        border-radius: 50% !important;
    }

</style>
