<!--=========================================================================================
  File Name: BettingResults.vue
  Description: Game Result BettingResults
==========================================================================================-->

<template>
    <div class="bet-res" v-if="result != undefined">
          <transition name="fade">
              <b-row class="result-row">
                  <b-col>
                      <img v-bind:src="imgPath">
                  </b-col>
              </b-row>
          </transition>
    </div>
</template>
<script>
    export default {
        name: 'BettingResults',
        props: {
            gameType: {required: true, type: String}
        },
        computed: {

            result() {
                return this.$store.state.gameModule.currentRoom.result;
            },
            imgPath() {

                if(this.gameType == 'DT') return "/assets/images/gameroom/results/" + this.result + "_winDT.png"
                else return "/assets/images/gameroom/results/" + this.result + "_win.png"
            }
        },
    }
</script>
<style>
.bet-res {
  position: absolute;
  z-index: 15;
  right: 0;
  height: 40vh;
  width: 100%;
  text-align: center;
}

.bet-res img {
  max-width: 100%;
}

.result-row {
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
