<!--=========================================================================================
  File Name: ResultRoad.vue
  Description: Result Road Component
==========================================================================================-->
<template>
  <div class="content">
    <div class="bead-road grid-y-repeat-pcg" ref="shoe-tbl">
      <img
        v-if="showLeftArrow"
        @click="scrollTarget('left')"
        src="/assets/images/gamelobby/btn_hisprev.png"
        alt="Prev Button"
        class="shoe-prev-gr"
      />
      <div
        class="rs-ctnr d-flex flex-rowgrid-y-repeat-pcg"
        v-bind:style="{ width: shoeW }"
      >
        <div class="flex-column" v-for="(shoeM, x) in shoeMatrix" :key="x">
          <div
            v-for="(shoe, i) in shoeM.split('')"
            :key="i"
            style="height: 4.6vh;width: 4.56vh"
          >
            <div
              class="shoe-item shoe-text"
              style="height:4.3vh;width: 4.3vh;"
              v-bind:class="[
                {
                  animate_latest:
                    x == shoeMatrix.length - 1 &&
                    shoeM.split('').length - 1 == i &&
                    $getLastResultRoadtIndex(shoeMatrix, x) == i &&
                    !hidePredict && predict.length <= 0,
                },
                {
                  animate_predict:
                    x == shoeMatrix.length - 1 &&
                    shoeM.split('').length - 1 == i &&
                    $getLastResultRoadtIndex(shoeMatrix, x) == i &&
                    hidePredict,
                },
                shoe,
              ]"
            >
              <span class="res-span-gr" v-if="gameType != 'DT'">{{
                shoe | shoeText
              }}</span>
              <span class="res-span-gr" v-if="gameType == 'DT'">{{
                shoe | shoeTextDT
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <img v-if="showRightArrow" @click="scrollTarget('right')" src="/assets/images/gamelobby/btn_hisnext.png" alt="Next Button" class="shoe-next"> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultRoad",
  components: {},
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      showRightArrow: false,
      showLeftArrow: false,
      width: 0,
      window: {
        width: 0,
        height: 0,
      },
      scrollW: 0,
      shoeW: "3000px",
    };
  },
  props: {
    shoeResults: {
      required: true,
      type: String,
    },
    gameType: {
      required: true,
      type: String,
    },
    predict: {
      required: false,
      type: String,
    },
    hidePredict: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    shoeMatrix() {
      // divide shoe by 6
      let shoe = [];
      if (this.predict.length > 0 && this.hidePredict) {
        shoe = this.shoeResults + this.predict;
      } else {
        shoe = this.shoeResults;
      }
      return shoe.match(/.{1,6}/g) || [];
    },
  },
  watch: {
    shoeMatrix: {
      handler() {
        this.handleResize();
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    setTimeout(
      function() {
        this.handleResize();
      }.bind(this),
      1000
    );
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.scrollW = Math.ceil((this.window.height * 4.5) / 100);
      if (this.shoeMatrix.length > 10) {
        if (this.$refs["shoe-tbl"])
          this.$refs["shoe-tbl"].scrollLeft =
            (this.shoeMatrix.length - 10) * this.scrollW;
      }
    },
    scrollTarget(move) {
      if (move == "right") this.$refs["shoe-tbl"].scrollLeft += this.scrollW;
      else this.$refs["shoe-tbl"].scrollLeft -= this.scrollW;
    },
  },
};
</script>

<style>
.rs-ctnr {
  width: 3000px;
}

.bead-road {
  overflow: hidden;
}

.res-span-gr {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-weight: 900 !important;
  font-size: 1.5vh;
}
</style>
