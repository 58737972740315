<!--=========================================================================================
  File Name: BettingAction.vue
  Description: Betting Action Component
==========================================================================================-->
<template>
    <div class="bet-buttons">
        <b-row>
            <b-col cols = '4'>
                 <b-button variant="success" @click="sendBet()" class='bet-buttons send-button'>{{ $t('game.sendBet') }}</b-button>
            </b-col>
            <b-col cols = '4'>
                <b-button variant="danger" @click="cancelBet()" class='bet-buttons cancel-button'>{{ $t('game.cancelBet') }}</b-button>
            </b-col>
            <b-col cols = '4'>
                <b-button variant="primary" @click="clearBet()" class='bet-buttons clear-button'>{{ $t('game.clearBet') }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
	name: 'BettingActions',
	data() {
		return {
            initBetting: true
        }
	},
    computed: {
		limit() {
			return this.$store.getters['gameModule/getCurrentLevelLimit']
		},
		currentRoom() {
			return this.$store.state.gameModule.currentRoom
		},
        isBetClosed() {
            return this.currentRoom.state != 1
		},
        cancelTime() {
            return parseInt(this.$store.state.gameModule.settings.cancelTime)
		},
        cancelType() {
            return this.$store.state.gameModule.settings.cancelType
		},
		currentTimer() {
			return this.$store.state.gameModule.currentRoom.timer
		}
	},
	props: {
		bet: {
            required: true, type: Array
		},
		successfulBet: {
            required: true, type: Object
		},
		prevBetTime: {
            required: true, type: Number
		}
	},
    methods: {
        sendBet() {

			if (this.isBetClosed || this.initBetting) {
				this.$emit('setErrorMessage', 'BET_CLOSED');
				return false;
			}

			if (this.bet.length == 0) {
				this.$emit('setErrorMessage', 'NO_BET');
				return false;
            }

			let totalBetAmount = 0, bettings = [], currentbettings = [], currentBetObj;

			bettings = this.bet;
			currentbettings = [];

			//bettings.forEach(bet =>  totalBetAmount += parseInt(bet.betamt));

			bettings.forEach((key, item) => {
				switch (bettings[item].betpos) {
					case 'player':
						currentBetObj = {
							betpos: bettings[item].betpos,
							betamt: bettings[item].betamt
						};
						currentbettings.push(currentBetObj);
						break;
					case 'banker':
						currentBetObj = {
							betpos: bettings[item].betpos,
							betamt: bettings[item].betamt
						};
						currentbettings.push(currentBetObj);
						break;
					case 'player_pair':
						currentBetObj = {
							betpos: bettings[item].betpos,
							betamt: bettings[item].betamt
						};
						currentbettings.push(currentBetObj);
						break;
					case 'banker_pair':
						currentBetObj = {
							betpos: bettings[item].betpos,
							betamt: bettings[item].betamt
						};
						currentbettings.push(currentBetObj);
						break;
					case 'tie':
						currentBetObj = {
							betpos: bettings[item].betpos,
							betamt: bettings[item].betamt
						};
						currentbettings.push(currentBetObj);
						break;
				}
				totalBetAmount += parseInt(bettings[item].betamt);
			});

			let betData = {
				gameCode: this.$store.state.gameModule.game,
				roomno: this.currentRoom.roomno,
				roomorder: this.currentRoom.roomorder,
				level: this.$store.state.gameModule.currentLevel,
				currency: this.$store.state.gameModule.member.currency,
				bettings,
				currentbettings,
				totalBetAmount
			};

            // Dispatch Submit Betting
			this.$store.dispatch('betModule/betSubmit', betData);
			this.clearBet();
		},
		clearBet() {
			this.$emit('initBet');
		},
		cancelBet() {
			this.clearBet();
			
			if (this.prevBetTime != 0) {
				if (this.cancelType == 'FROM_TIMER') {
					if (this.cancelTime > this.currentTimer) {
						this.$emit('setErrorMessage', 'CANCEL_OVERTIME');
					} else {
						this.$store.dispatch('betModule/betCancel');
					}
				} else if (this.cancelType == 'FROM_BET') {
					var secondBetweenTwoDate = Math.abs((new Date().getTime() - this.prevBetTime) / 1000);
					if (secondBetweenTwoDate > this.cancelTime) {
						this.$emit('setErrorMessage', 'CANCEL_OVERTIME');
					} else {
						this.$store.dispatch('betModule/betCancel');
					}
				}
			}
		}
	},
	watch: {
        isBetClosed: {
            handler(){
                if (this.$store.state.gameModule.currentRoom.state != 1 && this.initBetting) {
                    this.initBetting = false;
                }
            },
            deep: true
		}
	}
}
</script>
